<template>
  <div v-if="newSubData.userInfo" id="userInfo">
    <ul class="from">
      <li class="fromItem">
        <div class="key">姓名：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.realname }}</span>
          <!-- <span>{{ userData.idCard }}</span> -->
          <div style="display: inline-block">已完成实名认证，无法修改</div>
          <!--          <img src="@/assets/img/userInfo/bi.png" v-if="edit" alt="" />-->
        </div>
      </li>
      <li class="fromItem">
        <div class="key">学者图片：</div>
        <div class="value">
          <img class="img" v-lazy="$global.imgUrl + url" alt />
          <Upload
            accept=".png,.jpg,.jpeg"
            :headers="{ token: token }"
            :show-upload-list="false"
            class="upload"
            :action="`${$global.url1}/zw-public/web/common/upload`"
            :on-success="handleSuccess"
            :on-format-error="handleFormatError"
          >
            <img src="@/assets/img/userInfo/bi.png" v-if="edit" alt />
          </Upload>
        </div>
      </li>
      <li class="fromItem">
        <div class="key">性别：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.sex }}</span>
          <img
            @click="
              changeDetails({
                name: '性别',
                type: 'sel',
                value: '',
                key: 'sex',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">民族：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.nation }}</span>
          <img
            @click="
              changeDetails({
                name: '民族',
                type: 'input',
                value: '',
                key: 'nation',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">出生年月：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.birthday | dayFormate }}</span>
          <img
            @click="
              changeDetails({
                name: '出生年月',
                type: 'sel',
                value: '',
                key: 'birthday',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">籍贯：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.nationPlace }}</span>
          <img
            @click="
              changeDetails({
                name: '籍贯',
                type: 'input',
                value: '',
                key: 'nationPlace',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">学历/学位：</div>
        <div class="value">
          <span style="margin-right: 0">{{
            newSubData.userInfo.education
          }}</span>
          <span v-show="newSubData.userInfo.inStudy == 1">（在读）</span>
          <img
            v-if="edit"
            src="@/assets/img/userInfo/bi.png"
            alt
            @click="
              changeDetails({
                name: '学历/学位',
                type: 'input',
                value: newSubData.userInfo.eduVal,
                secondValue: newSubData.userInfo.education,
                inStudy: newSubData.userInfo.inStudy == 1,
                key: 'eduVal',
              })
            "
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">毕业院校：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.school }}</span>
          <img
            @click="
              changeDetails({
                name: '毕业院校',
                type: 'input',
                value: '',
                key: 'school',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">现单位/院校：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.unit }}</span>
          <img
            @click="
              changeDetails({
                name: '现单位/院校',
                type: 'input',
                value: '',
                key: 'unit',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">现任职位：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.job }}</span>
          <img
            @click="
              changeDetails({
                name: '现任职位',
                type: 'input',
                value: '',
                key: 'job',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">现单位\院校地址：</div>
        <div class="value">
          <span>{{ newSubData.userInfo.unitAddr }}</span>
          <img
            @click="
              changeDetails({
                name: '现单位\\院校地址',
                type: 'input',
                value: '',
                key: 'unitAddr',
              })
            "
            src="@/assets/img/userInfo/bi.png"
            v-if="edit"
            alt
          />
        </div>
      </li>
      <li class="fromItem">
        <div class="key">原单位\院校：</div>
        <div class="box" style="display: flex; width: 900px; flex-wrap: wrap">
          <div
            class="value"
            style="white-space: nowrap"
            v-show="scholarOriginalUnit.length !== 0"
            v-for="(item, i) in scholarOriginalUnit"
            :key="i"
          >
            <span style="margin-right: 10px">{{ item.unitName }}</span>
            <img
              @click="
                delUnit({
                  name: '原单位\\院校',
                  type: 'input',
                  value: '',
                  key: 'Historyjob',
                  index: i,
                })
              "
              style="margin-right: 20px; vertical-align: middle"
              src="@/assets/img/userInfo/2222.png"
              v-if="edit"
              alt
            />
          </div>
          <div class="value">
            <img
              @click="
                changeDetails({
                  name: '原单位\\院校',
                  type: 'input',
                  value: '',
                  key: 'Historyjob',
                  index: 0,
                })
              "
              style="margin-right: 20px"
              src="@/assets/img/userInfo/bi.png"
              v-if="edit"
              alt
            />
          </div>
        </div>
      </li>
      <li class="fromItem">
        <div class="key">职称：</div>
        <div class="box" style="display: flex; width: 900px; flex-wrap: wrap">
          <div class="value">
            <span v-for="item in sysUserTitle" :key="item.id">
              {{ item.levelTwo }}（{{ item.levelOneName }}）</span
            >
            <img
              @click="setLevel"
              src="@/assets/img/userInfo/bi.png"
              v-if="edit"
              alt
            />
          </div>
        </div>
      </li>
      <li class="fromItem">
        <div class="key">研究领域：</div>
        <div class="box" style="display: flex; width: 900px; flex-wrap: wrap">
          <div class="value">
            <span v-for="item in sysUserSubject" :key="item.id">{{
              item.levelTwoName
            }}</span>
            <img
              @click="setsubJect"
              src="@/assets/img/userInfo/bi.png"
              v-if="edit"
              alt
            />
          </div>
        </div>
      </li>
      <li class="fromItem">
        <div class="key">个人简介：</div>
        <div class="value">
          <div class="box" style="width: 900px">
            <span v-text="newSubData.userInfo.profile"></span>
            <img
              @click="
                changeDetails({
                  name: '个人简介',
                  type: 'input',
                  value: '',
                  key: 'profile',
                })
              "
              src="@/assets/img/userInfo/bi.png"
              v-if="edit"
              alt
            />
          </div>
        </div>
      </li>
    </ul>
    <div class="btn">
      <div v-if="!edit" @click="setEdit" :class="isEdit ? 'affirm' : 'close'">
        {{ isEdit ? "编辑" : "审核中" }}
      </div>
      <div v-if="edit" @click="back" class="close">取消</div>
      <Button v-throttleClick v-if="edit" @click="sub" class="affirm"
        >提交</Button
      >
    </div>
    <Modal
      :scrollable="true"
      v-model="alert"
      :width="changeObj.size ? changeObj.size : '500px'"
      class-name="vertical-center-modal"
      :footer-hide="true"
    >
      <div class="alert">
        <div class="title">编辑{{ changeObj.name }}</div>
        <div class="content">
          <template v-if="changeObj.key === 'sex'">
            <Select v-model="changeObj.value">
              <Option value="男">男</Option>
              <Option value="女">女</Option>
            </Select>
          </template>
          <template v-if="changeObj.key === 'eduVal'">
            <div style="display: flex; align-items: center">
              <Cascader
                :data="educationOptions"
                v-model="changeObj.value"
                @on-change="educationChange"
              ></Cascader>
              <Checkbox
                style="margin-left: 20px"
                :disabled="changeObj.disabled"
                v-model="changeObj.inStudy"
                >在读</Checkbox
              >
            </div>
          </template>
          <template v-if="changeObj.key === 'profile'">
            <Input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              maxlength="1000"
              v-model="changeObj.value"
              :placeholder="`请输入${changeObj.name}`"
            />
            <div class="wordsNum">{{ changeObj.value.length + " / 1000" }}</div>
          </template>
          <template
            v-if="
              changeObj.key === 'nation' ||
              changeObj.key === 'nationPlace' ||
              changeObj.key === 'school' ||
              changeObj.key === 'unit' ||
              changeObj.key === 'unitAddr' ||
              changeObj.key === 'job' ||
              changeObj.key === 'Historyjob'
            "
          >
            <Input
              v-model="changeObj.value"
              style="width: 420px; margin-top: 20px"
              :placeholder="`请输入${changeObj.name}`"
            />
          </template>
          <template v-if="changeObj.key === 'birthday'">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              @on-change="handleChange"
              :value="changeObj.value"
              :options="endStartStr"
              :editable="false"
              style="width: 100%"
            ></DatePicker>
          </template>
          <template v-if="changeObj.key === 'levelThreeName'">
            <div
              class="title-list"
              v-for="(item, index) in addList"
              :key="index"
            >
              <Select
                style="width: 150px; margin-right: 10px"
                placeholder="请选择职称级别"
                v-model="item.levelOne"
                label-in-value
                @on-change="($event) => titlesSelectChange(index, $event)"
              >
                <Option
                  v-for="obj in titleOptions"
                  :key="obj.titleCode"
                  :value="obj.titleCode"
                  >{{ obj.titleName }}</Option
                >
              </Select>
              <Input
                style="width: 250px; margin-right: 10px"
                placeholder="请输入职称"
                v-model="item.levelTwo"
              />
              <Icon
                style="cursor: pointer; vertical-align: middle"
                :type="
                  index < addList.length - 1 || index === 4
                    ? 'md-remove-circle'
                    : 'md-add-circle'
                "
                size="26"
                color="#999999"
                @click="addTitles(item, index)"
              />
            </div>
          </template>
          <template v-if="changeObj.key === 'setsubJect'">
            <div class="leaveOne subject">
              <div
                :class="[
                  'oneItem',
                  index === activeOneIndex ? 'activeOneItem' : '',
                ]"
                @click="getsubjectTwo(item, index)"
                v-for="(item, index) in setsubJect1"
                :key="index"
              >
                {{ item.subjectName }}
              </div>
            </div>
            <div class="activeTitle">选择第二级别</div>
            <div v-if="setsubJect2.length !== 0" class="leaveOne subject">
              <div
                :class="[
                  'twoItem',
                  index === activeTwoIndex ? 'activeTwoItem' : '',
                ]"
                v-for="(item, index) in setsubJect2"
                @click="spanAdd1(item)"
                :key="index"
              >
                {{ item.subjectName }}
              </div>
            </div>
            <div>
              <div class="activeTitle">已选择的研究领域：</div>
              <div class="addList">
                <div
                  class="activeItem"
                  :key="item.id"
                  v-for="(item, i) in addList1"
                >
                  {{ item.levelTwoName }}
                  <span class="activeClose" @click="deleteAdd1(i)"> X </span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="btn">
          <Button @click="closeAlert" class="close">取消</Button>
          <Button @click="affirm" class="affirm" v-throttleClick="2000"
            >保存</Button
          >
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "userInfo",
  data() {
    return {
      userData: {},
      sysUserPrize: {},
      scholarExperiences: {},
      scholarOriginalUnit: {},
      sysUserTitle: {},
      sysUserSubject: {},
      isEdit: false,
      newSubData: {},
      alert: false,
      edit: false,
      url: "",
      changeObj: {
        key: "",
        type: "",
        name: "",
        value: "",
        index: null,
      },
      titleOptions: [],
      addList: [],
      addList1: [],
      setsubJect1: [],
      setsubJect2: [],
      setsubJect3: [],
      oneObj: {},
      TwoObj: {},
      ThreeObj: {},
      subObj1: {},
      subObj2: {},
      subObj3: {},
      subData: {
        originalUnits: [],
        subjects: [],
        titles: [],
        userInfo: {},
      },
      activeOneIndex: "",
      activeTwoIndex: "",
      educationOptions: [],
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    endStartStr() {
      return {
        disabledDate: (data) => {
          let d = new Date(data).valueOf();
          return data && new Date().valueOf() < d;
        },
      };
    },
  },
  watch: {
    newSubData(newValue) {
      this.url = newValue.userInfo.headPhoto;
    },
    sysUserTitle(newValue) {
      this.addList = newValue;
    },
    sysUserSubject(newValue) {
      this.addList1 = newValue;
    },
  },
  filters: {
    reviewStatusFilter: function (num) {
      if (num === 0) return `审核中`;
      if (num === 1) return `编辑`;
    },
  },
  created() {
    this.getData();
    this.getEducationOptions();
  },
  methods: {
    educationChange(value, selectedData) {
      if (value.length) {
        this.changeObj.secondValue = selectedData[1].label;
        if (value[1] == "5" || value[1] == "8" || value[1] == "10") {
          this.changeObj.inStudy = false;
          this.changeObj.disabled = true;
        } else {
          this.changeObj.disabled = false;
        }
      } else {
        this.changeObj.secondValue = "";
        this.changeObj.inStudy = false;
        this.changeObj.disabled = true;
      }
    },
    async getEducationOptions() {
      let res = await this.$apis.userServe.educationOptions();
      this.educationOptions = res.data.map((item) => ({
        value: item.value,
        label: item.label,
        children: item.childrens,
      }));
    },
    // 职称多项添加或者删除
    addTitles(item, index) {
      if (index < this.addList.length - 1 || index === 4) {
        this.addList.splice(index, 1);
      } else {
        if (item.levelOne && item.levelTwo) {
          this.addList.push({
            levelOne: "",
            levelTwo: "",
          });
        }
      }
    },
    // 职称选择
    titlesSelectChange(index, query) {
      this.addList[index].levelOneName = query.label;
    },
    format(id) {
      return this.addList.some((item) => item.levelThree === id);
    },
    async getData() {
      let res = await this.$apis.userServe.userEditType();
      this.newSubData = res.content.baseInfo;
      this.isEdit = res.content.editable;
      this.scholarOriginalUnit = res.content.baseInfo.originalUnits;
      this.sysUserTitle = res.content.baseInfo.titles || [];
      this.sysUserSubject = res.content.baseInfo.subjects || [];
    },
    async getUserDetails() {
      let res = await this.$apis.userServe.userDetails();
      this.userData = res.content.detail.userInfo;
      // this.sysUserPrize = res.content.detail.sysUserPrize;
      // this.scholarExperiences = res.content.detail.scholarExperiences;
      this.scholarOriginalUnit = res.content.detail.scholarOriginalUnit;
      this.sysUserTitle = res.content.detail.sysUserTitle || [];
      this.sysUserSubject = res.content.detail.sysUserSubject || [];
      await this.getData();
    },
    delUnit(e) {
      this.scholarOriginalUnit.splice(e.index, 1);
    },
    back() {
      this.getData();
      this.edit = false;
      this.$emit("getData");
    },
    sub() {
      this.scholarOriginalUnit.forEach((item) => {
        this.subData.originalUnits.push({ unitName: item.unitName });
      });
      let obj = {
        subjects: this.sysUserSubject,
        titles: this.addList,
        userInfo: this.newSubData.userInfo,
        originalUnits: this.scholarOriginalUnit,
      };
      this.$apis.userServe.setUserInfo(obj).then(({ data }) => {
        if (data.resultCode == "201") {
          this.$Message.error(data.content.msg);
          return;
        } else {
          this.$Message.success(data.resultDesc);
        }
        this.getData();
        this.edit = false;
        this.$emit("getData");
      });
    },
    deleteItem(i) {
      this.addList.splice(i, 1);
    },
    deleteAdd1(i) {
      this.addList1.splice(i, 1);
    },
    handleChange(e) {
      this.changeObj.value = e;
    },
    async getSubJectthree(e, index) {
      this.subObj2 = {
        levelTwo: e.id,
        levelTwoName: e.subjectName,
      };
      let obj = { prevSubjectId: e.id };
      let res = await this.$apis.userServe.getThreeList(obj);
      this.setsubJect3 = res.content.researchThreeList;
      this.activeTwoIndex = index;
    },
    spanAdd1(e) {
      this.subObj2 = {
        levelTwo: e.id,
        levelTwoName: e.subjectName,
      };
      let add = { ...this.subObj1, ...this.subObj2 };
      if (this.addList1.length === 0) {
        this.addList1.push(add);
      } else {
        this.addList1.push(add);
        let falg = true;
        this.addList1.forEach((item) => {
          if (item.levelTwo == add.levelTwo) {
            falg = false;
          }
        });
        if (falg) {
          this.addList1.push(add);
        }
      }
      this.sysUserSubject = this.addList1;
    },
    async getsubjectTwo(e, index) {
      this.subObj1 = {
        levelOne: e.id,
        levelOneName: e.subjectName,
      };
      let res = await this.$apis.userServe.getTwoList({
        prevSubjectId: e.id,
      });
      this.setsubJect2 = res.content.researchTwoList;
      this.activeOneIndex = index;
      this.activeTwoIndex = "";
    },
    handleFormatError(file) {
      console.log(file);
      // this.$Notice.warning({
      //   title: "The file format is incorrect",
      //   desc:
      //     "File format of " +
      //     file.name +
      //     " is incorrect, please select jpg or png.",
      // });
    },
    async setsubJect() {
      this.changeObj = {
        key: "setsubJect",
        name: "研究领域",
        value: "",
        size: "1000px",
      };
      let res = await this.$apis.userServe.getOneList();
      this.setsubJect1 = res.content.researchOneList;
      this.alert = true;
    },
    async setLevel() {
      this.changeObj = {
        key: "levelThreeName",
        name: "职称",
        value: "",
        size: "600",
      };
      let res = await this.$apis.userServe.getTitleList();
      this.titleOptions = [...res.data];
      this.alert = true;
    },
    changeDetails(obj) {
      let modalObj = obj;
      if (modalObj.key === "eduVal") {
        if (modalObj.value) {
          modalObj.value = modalObj.value.split(',')
          modalObj.disabled =
            modalObj.value[1] == "5" ||
            modalObj.value[1] == "8" ||
            modalObj.value[1] == "10";
        } else {
          modalObj.disabled = true
        }
      }
      this.changeObj = modalObj;
      this.alert = true;
    },
    handleSuccess(res, file) {
      console.log(res, file, "res, file");
      this.url = res.content.url;
      this.newSubData.userInfo["headPhoto"] = this.url;
      console.log(this.url);
    },
    setEdit() {
      if (!this.isEdit) return;
      this.edit = true;
    },
    closeAlert() {
      this.alert = false;
    },
    affirm() {
      if (this.changeObj.key === "sex") {
        this.subData.userInfo["sex"] = this.changeObj.value;
        this.newSubData.userInfo["sex"] = this.changeObj.value;
      } else if (this.changeObj.key === "Historyjob") {
        if (this.changeObj.value == "") {
          this.$Message.error("原单位院校不能为空");
          return;
        }
        if (
          this.changeObj.value.length > 10 ||
          this.scholarOriginalUnit.length >= 10
        ) {
          this.$Message.error("原单位院校已达上限");
          return;
        }
        let obj = {
          unitName: this.changeObj.value,
        };
        this.scholarOriginalUnit.push(obj);
      } else if (this.changeObj.key === "birthday") {
        this.subData.userInfo["birthday"] = this.changeObj.value;
        this.newSubData.userInfo["birthday"] = this.changeObj.value;
      } else if (this.changeObj.key === "levelThreeName") {
        if (this.addList.length > 5) {
          this.$Message.error("职称已达上限");
          return;
        }
        this.subData.titles = this.addList;
      } else if (this.changeObj.key === "setsubJect") {
        if (this.addList1.length > 5) {
          this.$Message.error("研究领域已达上限");
          return;
        }
        this.sysUserSubject = this.addList1;
        this.subData.subjects = this.addList1;
      } else if (this.changeObj.key === "eduVal") {
        this.subData.userInfo.inStudy = this.changeObj.inStudy ? 1 : 0;
        this.newSubData.userInfo.inStudy = this.changeObj.inStudy ? 1 : 0;
        this.subData.userInfo.education = this.changeObj.secondValue;
        this.newSubData.userInfo.education = this.changeObj.secondValue;
        this.subData.userInfo[this.changeObj.key] = this.changeObj.value.join();
        this.newSubData.userInfo[this.changeObj.key] =
          this.changeObj.value.join();
      } else {
        if (this.changeObj.key == "nation") {
          if (this.changeObj.value.length > 10) {
            this.$Message.error("民族已达上限");
            return;
          }
        }
        if (this.changeObj.key == "nationPlace") {
          if (this.changeObj.value.length > 30) {
            this.$Message.error("籍贯已达上限");
            return;
          }
        }
        if (this.changeObj.key == "school") {
          if (this.changeObj.value.length > 20) {
            this.$Message.error("毕业院校已达上限");
            return;
          }
        }
        if (this.changeObj.key == "unit") {
          if (this.changeObj.value.length > 20) {
            this.$Message.error("现单位/院校已达上限");
            return;
          }
        }
        if (this.changeObj.key == "job") {
          if (this.changeObj.value.length > 20) {
            this.$Message.error("现任职位已达上限");
            return;
          }
        }
        if (this.changeObj.key == "unitAddr") {
          if (this.changeObj.value.length > 30) {
            this.$Message.error("现单位院校地址已达上限");
            return;
          }
        }
        if (this.changeObj.key == "profile") {
          if (this.changeObj.value.length > 1000) {
            this.$Message.error("个人简介已达上限");
            return;
          }
        }
        this.subData.userInfo[this.changeObj.key] = this.changeObj.value;
        this.newSubData.userInfo[this.changeObj.key] = this.changeObj.value;
      }
      this.alert = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal-content {
  border-radius: 18px;
  .ivu-modal-close {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #00a4ff;
    border-radius: 0px 18px 0px 18px;
    top: 0;
    right: 0;
    overflow: hidden;
    .ivu-icon-ios-close {
      font-size: 26px;
      color: #ffffff;
    }
  }
  .ivu-modal-body {
    padding: 40px;
  }
}
.alert {
  .title-list {
    margin-bottom: 10px;
  }
  .subject::-webkit-scrollbar {
    width: 4px;
  }

  .subject::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  .subject::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .subject {
    max-height: 250px;
    overflow-y: scroll;
  }
  .wordsNum {
    text-align: right;
  }

  .btn {
    margin-top: 34px;
    text-align: right;

    .close {
      margin-right: 20px;
      width: 72px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px dotted #00a4ff;
      border-radius: 8px;
      color: #00a4ff;
      cursor: pointer;
    }

    .affirm {
      text-align: center;
      line-height: 38px;
      width: 70px;
      height: 36px;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .activeTitle {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #333333;
  }

  .addList {
    display: flex;
    flex-wrap: wrap;
    .activeItem {
      margin-right: 25px;
      margin-bottom: 10px;
      padding: 6px 16px;
      white-space: nowrap;
      border: 1px solid #00a4ff;
      color: #00a4ff;
      border-radius: 5px;
      .activeClose {
        cursor: pointer;
      }
    }
  }

  .leaveOne {
    // margin-top: 20px;
    // width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;

    .oneItem {
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      padding: 8px 18px;
      border: 1px solid #999999;
      border-radius: 5px;
    }
    .activeOneItem {
      border: 1px solid #00a4ff;
      color: #00a4ff;
    }

    .threeItem {
      margin-top: 20px;
      width: 100%;

      .name {
        display: flex;
        align-items: baseline;
        .positionItem {
          color: #333;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 5px;
          padding: 6px 16px;
          border: 1px solid #999999;
          border-radius: 5px;
        }
        .activeOneItem {
          border: 1px solid #00a4ff;
          color: #00a4ff;
        }
      }
    }

    .twoItem {
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 6px 16px;
      border: 1px solid #999999;
      border-radius: 5px;
      cursor: pointer;
    }
    .activeTwoItem {
      border: 1px solid #00a4ff;
      color: #00a4ff;
    }
  }

  .title {
    margin-bottom: 30px;
    height: 25px;
    line-height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    color: #333333;
  }
}

#userInfo {
  margin-top: 15px;
  min-height: 790px;
  padding-top: 50px;
  background: #ffffff;
  padding-bottom: 20px;
  border-radius: 10px;
  .upload {
    display: inline-block;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    //background: pink;
    .close {
      text-align: center;
      line-height: 38px;
      width: 72px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      margin-right: 13px;
      cursor: pointer;
    }

    .affirm {
      margin-left: 13px;
      width: 70px;
      height: 36px;
      line-height: 35px;
      text-align: center;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
      border: none;
    }
  }

  .from {
    margin-bottom: 30px;

    .fromItem {
      display: flex;
      min-height: 50px;
      margin-bottom: 10px;
      font-size: 16px;
      .key {
        margin-right: 5px;
        width: 120px;
        white-space: nowrap;
      }

      .value {
        // width: 500px;
        span {
          margin-right: 20px;
        }

        img {
          cursor: pointer;
        }

        div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }

        .img {
          width: 70px;
          height: 98px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
